.FontAwesome {
    cursor: default;
    left: 0;
    text-align: center;
    top: 1em;
    width: 100%;
}

.bottomBorder {
    border-top: 1in;
}

.FontAwesomeSpaced {
    text-align: center;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .FontAwesomeSpaced {
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .FontAwesomeSpaced {
        text-align: center;
        width: 100%;
    }
}