.VaporjawnBio {
    text-align: center;
    margin: 100px;
    font-family: Lato, Serif;
    letter-spacing: 2px;
    line-height: 2.15em;
    letter-spacing: 2px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.large-font{
    font-size: 28px;
    font-weight: normal;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}
.regular-font{
    font-size: 22px;
    font-weight: normal;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.VaporjawnBioGlow{
    color: #fff;
    text-align: center;
    text-shadow: 5px 5px 8px #fc466b;
    font-size: 45px;
    font-weight: 790;
    letter-spacing: 0.2em;
    border-bottom: solid 2px;
    border-top: solid 3px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    box-shadow: 0 3px 0 #3f5efb;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .VaporjawnBio {
        font-size: 18px;
    }
    
    .large-font{
        font-size: 18px;
    }

    .regular-font{
        font-size: 18px;
    }

    .VaporjawnBioGlow{
        font-size: 30px;
    }
}

@media screen and (max-width: 640px) {
    .VaporjawnBio {
        font-size: 16px;
    }
    
    .large-font{
        font-size: 16px;
    }

    .regular-font{
        font-size: 16px;
    }

    .VaporjawnBioGlow{
        font-size: 25px;
    }
}