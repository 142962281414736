.ProjectsContainer{
    width: 100%;
    padding: 93px;
    display: grid;
    grid-template-columns: 500px 500px 500px;
    column-gap: 100px;
    row-gap: 150px;    /* background-color: #181A1B; */
    background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}
.ProjectsPicture{
    width: 100%;
    max-height: 250px;
    margin-left: auto;
    margin-right: auto;
}

.ProjectsPictureContainer{
    width: 85%;
    padding: 93px;
    background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}

.ProjectsRowContainer{
    margin-left: -40px;
    padding-top: 5%;
    display: inline-block;
}

.ProjectsSectionContainer{
    padding-left: 0.5%;
    padding-right: 0.5%;
    margin: 0;
    background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
    margin-top: 20px;
    margin-left: 30%;
    margin-bottom: 50px;
    width: 400px;
    height: 400px;
    float: left;
}

.ProjectsSectionCover{
    padding-left: 0.5%;
    padding-right: 0.5%;
    margin: 0;
    background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
    top: 0;
    margin-top: -33.5%;
    margin-left: -.5%;
    width: 400px;
    height: 400px;
    float: left;
    z-index: -100;
}

.ProjectsSectionCoverText{
    text-align: center;
    margin-top: 45%;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    max-height: 30%;
    margin-left: auto;
    margin-right: auto;
}

.ProjectClickButton{
    background-color: #fc466b; 
    border:5px solid #3f5efb;
    padding: 10px 35px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 37%;
    cursor: pointer;
}
.ProjectViewButton{
    background-color: #fc466b;
    border:5px solid #3f5efb;
    padding: 10px 35px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 29%;
    margin-top: 25%;
    appearance: button;
    cursor: pointer;
}

.ProjectsContainerHeader{
    text-align: center;
    font-size: 28px;
}

.ProjectsPictureTitle {
    text-align: center;
    margin-top: -2%;

}

.ProjectsPictureText{
    text-align: center;
    letter-spacing: 0.466667px;
    line-height: 32.6667px;
    font-size: 18.6px;
    max-height: 31px;
    margin-top: -4%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.redLink{
    color: red;
}

.ProjectsFeedAboutButton{
    border: 4px solid;
    text-align:center;
    font-weight: bold;
    text-decoration: none;    
    width: 22%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 3%;
    background-image: linear-gradient(to bottom right, #3f5efb, #fc466b)
}
.ProjectsFeedAboutLink{
    color: black;
    text-decoration: none;  
}
.ProjectsFeedAboutLink :hover{
    color: white;
}

.ProjectButtonDeactivated {
    display: none;
}

.ProjectButtonActivated {
    display: block;
}

@media screen and (max-width: 1000px) {
    .ProjectsContainer{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .ProjectsPicture{
        width: 100%;
        max-height: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .ProjectsPictureContainer{
        width: 85%;
        padding: 93px;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
    }
    .ProjectsRowContainer{
        margin-left: -40px;
        padding-top: 5%;
        display: inline-block;
    }
    .ProjectsSectionContainer{
        padding-left: 0.5%;
        padding-right: 0.5%;
        margin: 0;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        margin-top: 20px;
        margin-left: 30%;
        margin-bottom: 50px;
        width: 400px;
        height: 400px;
        float: left;
    }
    .ProjectsSectionCover{
        padding-left: 0.5%;
        padding-right: 0.5%;
        margin: 0;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        top: 0;
        margin-top: -33.5%;
        margin-left: -.5%;
        width: 400px;
        height: 400px;
        float: left;
        z-index: -100;
    }
    .ProjectsSectionCoverText{
        text-align: center;
        margin-top: 45%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media screen and (max-width: 700px) {
    .ProjectsContainer{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .ProjectsPicture{
        width: 100%;
        max-height: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .ProjectsPictureContainer{
        width: 85%;
        padding: 93px;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
    }
    .ProjectsRowContainer{
        margin-left: -40px;
        padding-top: 5%;
        display: inline-block;
    }
    .ProjectsSectionContainer{
        padding-left: 0.5%;
        padding-right: 0.5%;
        margin: 0;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        margin-top: 20px;
        margin-left: 30%;
        margin-bottom: 50px;
        width: 400px;
        height: 400px;
        float: left;
    }
    .ProjectsSectionCover{
        padding-left: 0.5%;
        padding-right: 0.5%;
        margin: 0;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        top: 0;
        margin-top: -33.5%;
        margin-left: -.5%;
        width: 400px;
        height: 400px;
        float: left;
        z-index: -100;
    }
    .ProjectsSectionCoverText{
        text-align: center;
        margin-top: 45%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
}

@media screen and (max-width: 500px) {
    .ProjectsContainer{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .ProjectsPicture{
        width: 100%;
        max-height: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .ProjectsPictureContainer{
        width: 85%;
        padding: 93px;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
    }
    .ProjectsRowContainer{
        margin-left: -40px;
        padding-top: 5%;
        display: inline-block;
    }
    .ProjectsSectionContainer{
        padding-left: 0.5%;
        padding-right: 0.5%;
        margin: 0;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        margin-top: 20px;
        margin-left: 30%;
        margin-bottom: 50px;
        width: 400px;
        height: 400px;
        float: left;
    }
    .ProjectsSectionCover{
        padding-left: 0.5%;
        padding-right: 0.5%;
        margin: 0;
        background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
        top: 0;
        margin-top: -33.5%;
        margin-left: -.5%;
        width: 400px;
        height: 400px;
        float: left;
        z-index: -100;
    }
    .ProjectsSectionCoverText{
        text-align: center;
        margin-top: 45%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
}