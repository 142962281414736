.video {
	margin-top: 0;
}

.main20XXImage {
    width: 50%; 
    height: 50%;
    margin-left: 25%;
}

.yellowH1 {
    color: yellow; 
    font-size: 40px; 
    text-align: center;
}

.btn-primary {
	text-indent: -0.1rem;
}

/* Video BG CSS */
.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
    max-width:100%;
    height:auto;
  }

  .fullscreen-bg__video {
    filter: blur(8px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
      height: 300%;
      top: -100%;
    }
  }

  @media (max-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
      width: 300%;
      left: -100%;
    }
  }

  @media (max-width: 767px) {
    .fullscreen-bg__video {
      width:auto;
      height: 100%;
    }
  }