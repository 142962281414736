.TempleBio {
    text-align: center;
    margin: 100px;
    font-family: Lato, sans-serif;
    letter-spacing: 2px;
    line-height: 2.15em;
    letter-spacing: 2px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.large-font{
    font-size: 28px;
    font-weight: normal;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}
.regular-font{
    font-size: 22px;
    font-weight: normal;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.TempleBioGlow{
    color: #fff;
    text-align: center;
    text-shadow: 5px 5px 8px #9D2235;
    font-size: 45px;
    font-weight: 790;
    letter-spacing: 0.2em;
    border-bottom: solid 2px;
    border-top: solid 3px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    box-shadow: 0 3px 0 darkred;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .TempleBio {
        font-size: 18px;
    }

    .large-font{
        font-size: 18px;
    }

    .regular-font{
        font-size: 18px;
    }

    .TempleBioGlow{
        font-size: 30px;
    }
}
@media screen and (max-width: 640px) {
    .TempleBio {
        font-size: 18px;
    }

    .large-font{
        font-size: 18px;
    }

    .regular-font{
        font-size: 18px;
    }

    .TempleBioGlow{
        font-size: 30px;
    }
}