.VaporjawnBackgroundBanner{
    padding: 104px 0px;
    padding-top: 0;
    background-attachment: scroll;
    background-image: url("../../../assets/images/VaporjawnBanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: auto;
    background-attachment: fixed;
}

@media screen and (max-width: 768px) {
    .VaporjawnBackgroundBanner{
        padding: 104px 0px;
        padding-top: 0;
        background-attachment: scroll;
        background-image: url("../../../assets/images/VaporjawnBanner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: auto;
        background-attachment: fixed;
    }
}

@media screen and (max-width: 640px) {
    .VaporjawnBackgroundBanner{
        padding: 104px 0px;
        padding-top: 0;
        background-attachment: scroll;
        background-image: url("../../../assets/images/VaporjawnBanner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: auto;
        background-attachment: fixed;
    }
}