.App-header {
    text-align: center;
    font-family: Lato, Serif;
    padding: 52px;
    margin: 90px;
    background: rgba(52, 27, 43, 0.5);
    width: 50%;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
}

.temple {
    color: #9D2235;
}

.temple:hover {
    color: white;
    text-shadow: 2px 2px 1px #9D2235;

}
.glow {
    color: #fff;
    text-align: center;
    text-shadow: 5px 5px 8px #0066ff;
    font-size: 50px;
    font-weight: 790;
    letter-spacing: 0.2em;
    border-bottom: solid 2px;
    border-top: solid 3px;
    text-transform: uppercase;
    box-shadow: 0 3px 0 darkred;
    width: 100%;

}

.blerb{
    font-size: 22px;
    color: white;
    letter-spacing: 2px;
    line-height: 1.75em;
}

@media screen and (max-width: 768px) {
    .App-header {
        width: 100%;
    }

    .glow {
        font-size: 40px;
    }

    .blerb{
        font-size: 16px;
    }
}

@media screen and (max-width: 640px) {
    .App-header {
        width: 100%;
    }

    .glow {
        font-size: 40px;
    }

    .blerb{
        font-size: 16px;
    }
}