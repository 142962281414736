.CTAGlow {
    color: #fff;
    text-align: center;
    text-shadow: 0 0 30px #0066ff;
    font-size: 40px;
}
.CTAGlow-small {
    color: #fff;
    text-align: center;
    text-shadow: 0 0 30px #0066ff;
    font-size: 20px;
}

.CTA {
    padding-top: 70px;
    padding-bottom: 70px;
}

@media screen and (max-width: 768px) {
    .CTA {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .CTAGlow {
        font-size: 30px;
    }

    .CTAGlow-small {
        font-size: 15px;
    }
}

@media screen and (max-width: 640px) {
    .CTA {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .CTAGlow {
        font-size: 30px;
    }

    .CTAGlow-small {
        font-size: 15px;
    }
}