.BackgroundBanner{
    padding: 104px 0px;
    padding-top: 0;
    background-attachment: scroll;
    background-image: url("../../assets/images/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: auto;
    background-attachment: fixed;
}

@media screen and (max-width: 768px) {
    .BackgroundBanner{
        padding: 104px 0px;
        padding-top: 0;
        background-attachment: scroll;
        background-image: url("../../assets/images/banner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: auto;
        background-attachment: fixed;
    }
}

@media screen and (max-width: 640px) {
    .BackgroundBanner{
        padding: 104px 0px;
        padding-top: 0;
        background-attachment: scroll;
        background-image: url("../../assets/images/banner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: auto;
        background-attachment: fixed;
    }
}