.ButtonCTA {
    float: left;
    list-style: none;
    text-align: center;
    background-color: #4c5fd7;
    border-color: #83d3c9;
    border-style: solid;
    margin-top: 20px;
    margin-left: 13%;
    width: 288px;
    height: 62;
    line-height: 70px;
}
    
.ButtonContainer {
    margin: 0;
    padding: 0;
    /* margin-left: 28.5%; */
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.ButtonContainer li:hover {
    background-color: #83d3c9;
    color: black;
    
    
}

.ButtonCTALink {
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    color:white;

}

@media screen and (max-width: 768px) {
    .ButtonCTALink {
        font-size: 14px;
    }

    .ButtonCTA {
        width: 100%;
    }

    .ButtonContainer {
        width: 100%;
    }

    .ButtonCTALink {
        font-size: 14px;
    }
}

@media screen and (max-width: 640px) {
    .ButtonCTALink {
        font-size: 12px;
    }

    .ButtonCTA {
        width: 100%;
    }

    .ButtonContainer {
        width: 100%;
    }

    .ButtonCTALink {
        font-size: 12px;
    }
}