.hackathonCTABanner {
    padding: 104px 0px;
    padding-top: 0;
    background-attachment: scroll;
    background-image: url("../../../assets/images/hackathonBanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

@media screen and (max-width: 768px) {
    .hackathonCTABanner {
        padding: 104px 0px;
        padding-top: 0;
        background-attachment: scroll;
        background-image: url("../../../assets/images/hackathonBanner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
}

@media screen and (max-width: 640px) {
    .hackathonCTABanner {
        padding: 104px 0px;
        padding-top: 0;
        background-attachment: scroll;
        background-image: url("../../../assets/images/hackathonBanner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
}