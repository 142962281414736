.instagramPicture{
    width: 100%;
    height: 100%;
}

.instagramPictureContainer{
    width: 85%;
    padding: 93px;
    background-image: linear-gradient(to bottom right, rgba(63, 94, 251, .5), rgba(252, 70, 107, .5));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}

.rowContainer{
    margin-left: -40px;
    padding-top: 5%;
    display: inline-block;
}

.sectionContainer{
    padding-left: 10.7%;
    width: 35%;
    margin: 0;
    float: left;
}

.instagramContainerHeader{
    text-align: center;
    font-size: 28px;
}

.instagramPictureTitle {
    text-align: center;

}

.instagramPictureText{
    text-align: center;
    letter-spacing: 0.466667px;
    line-height: 32.6667px;
    font-size: 18.6px;
}

.redLink{
    color: red;
}

.instagramFeedAboutButton{
    border: 4px solid;
    text-align:center;
    font-weight: bold;
    text-decoration: none;    
    width: 22%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    background-image: linear-gradient(to bottom right, #3f5efb, #fc466b)
}
.instagramFeedAboutLink{
    color: black;
    text-decoration: none;    
}
.instagramFeedAboutLink :hover{
    color: white;
}

@media screen and (max-width: 768px) {
    .instagramPictureContainer{
        width: 100%;
        padding: 0;
    }
    .rowContainer{
        margin-left: 0;
        padding-top: 0;
        display: block;
    }
    .sectionContainer{
        padding-left: 0;
        width: 100%;
        margin: 0;
        float: none;
    }
    .instagramContainerHeader{
        font-size: 20px;
    }
    .instagramPictureTitle {
        font-size: 20px;
    }
    .instagramPictureText{
        font-size: 14px;
    }
    .instagramFeedAboutButton{
        font-size: 20px;
    }
}

@media screen and (max-width: 640px) {
    .instagramPictureContainer{
        width: 100%;
        padding: 0;
    }
    .rowContainer{
        margin-left: 0;
        padding-top: 0;
        display: block;
    }
    .sectionContainer{
        padding-left: 0;
        width: 100%;
        margin: 0;
        float: none;
    }
    .instagramContainerHeader{
        font-size: 20px;
    }
    .instagramPictureTitle {
        font-size: 20px;
    }
    .instagramPictureText{
        font-size: 14px;
    }
    .instagramFeedAboutButton{
        font-size: 20px;
    }
}