.smashWrapper-style2-container-special-alt{
    background-image: linear-gradient(to bottom right, rgba(157, 34, 53, 1), rgba(82, 77, 77, 0.5));
    background-repeat: no-repeat, no-repeat;
    color: white;
    padding: 100px;
    margin: 0px 190px 100px;
    font-size: 20px;
    height: 453px;
    font-family: Lato, sans-serif;
}
.smashInnerInfoBox {
    margin-left: 25px;
    width: 66.6666666667%;
    float: left;
    padding-top: 0!important;
    letter-spacing: 0.1em;

}
.smashInfoBoxTitle {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin-top: -30px;
}
.smashInfoBoxButtons {
    margin-bottom: 0;
    cursor: default;
    margin-left: -70px;
    margin-top: 65px;
}
.smashInfoBoxButtonSpecial{
    background: white;
    border-color: white;
    color: rgba(157, 34, 53, 1) !important;
    padding-left: 12px;
    padding-right: 12px;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8em;
    letter-spacing: 2px;
    min-width: 18em;
    padding: 0 0.75em;
    line-height: 3.75em;
    text-align: center;
}
.smashInfoBoxButtons :hover{
    background-image: linear-gradient(to top left, rgba(157, 34, 53, .7), rgba(82, 77, 77, 0.5));
    text-shadow: 2px 2px 1px white;
}
.smashInfoBoxPaddingLeft{
    margin-left: 20px;
}
.smashInfoBoxText {
    letter-spacing: 2px;
    line-height: 1.75em;
    font-family: Lato, sans-serif;
}

.smashFeaturedFAicons{
    width: 31%;
    display: inline-block;
    margin-top: -8%;
    opacity: .5;
}
.smashFeaturedFAiconsSection{
    display:inline-flex;
    gap: 10%;
    font-size: 45px;
    margin: 5%;
    padding-left: 10%;
}
.orangeRed {
    color: orangered;
}

@media screen and (max-width: 768px) {
    .smashWrapper-style2-container-special-alt{
        background-image: linear-gradient(to bottom right, rgba(157, 34, 53, 1), rgba(82, 77, 77, 0.5));
        background-repeat: no-repeat, no-repeat;
        color: white;
        padding: 100px;
        margin: 0px 190px 100px;
        font-size: 20px;
        height: 453px;
        font-family: Lato, sans-serif;
    }
    .smashInnerInfoBox {
        margin-left: 25px;
        width: 66.6666666667%;
        float: left;
        padding-top: 0!important;
        letter-spacing: 0.1em;

    }
    .smashInfoBoxTitle {
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: -30px;
    }
    .smashInfoBoxButtons {
        margin-bottom: 0;
        cursor: default;
        margin-left: -70px;
        margin-top: 65px;
    }
    .smashInfoBoxButtonSpecial{
        background: white;
        border-color: white;
        color: rgba(157, 34, 53, 1) !important;
        padding-left: 12px;
        padding-right: 12px;
        padding: 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        display: inline-block;
        font-size: 0.8em;
        letter-spacing: 2px;
        min-width: 18em;
        padding: 0 0.75em;
        line-height: 3.75em;
        text-align: center;
    }
}

@media screen and (max-width: 640px) {
    .smashWrapper-style2-container-special-alt{
        background-image: linear-gradient(to bottom right, rgba(157, 34, 53, 1), rgba(82, 77, 77, 0.5));
        background-repeat: no-repeat, no-repeat;
        color: white;
        padding: 100px;
        margin: 0px 190px 100px;
        font-size: 20px;
        height: 453px;
        font-family: Lato, sans-serif;
    }
    .smashInnerInfoBox {
        margin-left: 25px;
        width: 66.6666666667%;
        float: left;
        padding-top: 0!important;
        letter-spacing: 0.1em;

    }
    .smashInfoBoxTitle {
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: -30px;
    }
    .smashInfoBoxButtons {
        margin-bottom: 0;
        cursor: default;
        margin-left: -70px;
        margin-top: 65px;
    }
    .smashInfoBoxButtonSpecial{
        background: white;
        border-color: white;
        color: rgba(157, 34, 53, 1) !important;
        padding-left: 12px;
        padding-right: 12px;
        padding: 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        display: inline-block;
        font-size: 0.8em;
        letter-spacing: 2px;
        min-width: 18em;
        padding: 0 0.75em;
        line-height: 3.75em;
        text-align: center;
    }
}